// Variable overrides
$body-bg: #ffffff;

$theme-colors: (
  "primary": #575757
);

$top_panel_height: 56px;
$top_form_height: 38px;

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

// h2 {
//   color: blue;
// }

.title {
  margin-right: 1em;
}

.backgroundPic {
  background: url('./../../assets/img/icons/a.png') no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url('./../../assets/img/icons/b.png');
  }
}
.right {
  float: right;
  width: 32px;
  height: 32px;
}

/*** Notifications ***/
/*** Example rules ***/
.redux-toastr .toastr {
  border-radius: 0px;
  .rrt-title {
  }
  .rrt-text {
  }
  &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error, & button {
  }
  &.rrt-error {
  }
  &.rrt-success {
  }
  &.rrt-warning {
  }
  &.rrt-info {
  }
  .close-toastr {
    opacity: 0.6;
  }
}

.display-linebreak {
  white-space: pre-line;
}

.gray-panel {
  background-color: rgba(0, 0, 0, 0.03);
}

// quick fix for bootstrap validation markers for selects
select.form-control.is-valid,
select.form-control.is-invalid,
textarea.form-control.is-valid,
textarea.form-control.is-invalid {
    background-position: right calc(0.875em + 0.1875rem) center;
}

// custom width
.w-25 {
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-33 {
  width: 33% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-40 {
  width: 40% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-50 {
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-60 {
  width: 60% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-75 {
  overflow: hidden;
  text-overflow: ellipsis;
}

// disable anchors
a[disabled] {
  pointer-events: none;
}
a[disabled].btn-primary {
  color: #fff;
  background-color: #575757;
  border-color: #575757;
}
a[disabled].btn {
  opacity: 0.65;
}

// proper link colors
.link {
  color: #0000EE;
  text-decoration: underline;
  padding: 0;
}
.link:hover {
  color: #0000EE;
  text-decoration: none;
}

// avoid additional margin in translated fields
.card-text {
  [class~="form-group"]:last-of-type {
    margin-bottom: 0;
  }
}

// avoid too wide login name in menu
.login-menu a {
  width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-content-container {
  padding-top: $top_panel_height;
  height: calc(100vh - #{$top_panel_height});
  .home-container {
    height: calc(100vh - #{$top_panel_height});
    display: flex;
    flex-direction: column;
    .form-container {
      display: flex;
      align-items: center;
      gap: 40px;
      text-wrap: nowrap;
      margin: 10px;
      .form-group {
        display: flex;
        align-items: center;
        margin: 0;
        gap: 10px;
        label {
          margin: 0;
        }
        select {
          min-width: 200px;
        }
        input {
          width: 50px;
        }
      }
      .assistant {
        display: flex;
        flex-direction: row-reverse;
        flex-grow: 1;
        button {
          text-wrap: nowrap;
        }
      }
    }
    .xterm-container {
      display: block;
      width: 100%;
      padding-left: 1px;
      height: calc(100vh - #{$top_panel_height} - #{$top_form_height} - 20px);
      .terminal {
        padding: 2px;
      }
    }
  }
}
.assistant-chat {
  .conv {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .card {
      &.border-info {
        background-color: rgba(23, 162, 184, 0.15);
      }
      &.border-success {
        background-color: rgba(40, 167, 69, 0.15);
      }
    }
    .card-title {
      margin-bottom: 0;
    }
    .card-body {
      padding: 10px;
      div {
        min-height: 10px;
      }
    }
  }
}

.light-mode {
  .home-container {
    .xterm-dom-renderer-owner-1 .xterm-rows {
      color: black;
    }
    .xterm-dom-renderer-owner-1 .xterm-rows {
      background-color: white;
    }
  }
  @keyframes blink_box_shadow_11 { 50% {  border-bottom-style: hidden; }}
  @keyframes blink_block_11 { 0% {  background-color: black;  color: #000000; } 50% {  background-color: inherit;  color: black; }}
  .xterm-dom-renderer-owner-1 .xterm-rows.xterm-focus .xterm-cursor.xterm-cursor-blink:not(.xterm-cursor-block) { animation: blink_box_shadow_11 1s step-end infinite;}
  .xterm-dom-renderer-owner-1 .xterm-rows.xterm-focus .xterm-cursor.xterm-cursor-blink.xterm-cursor-block { animation: blink_block_11 1s step-end infinite;}
  .xterm-dom-renderer-owner-1 .xterm-rows .xterm-cursor.xterm-cursor-block { background-color: black; color: #000000;}
  .xterm-dom-renderer-owner-1 .xterm-rows .xterm-cursor.xterm-cursor-outline { outline: 1px solid black; outline-offset: -1px;}
  .xterm-dom-renderer-owner-1 .xterm-rows .xterm-cursor.xterm-cursor-bar { box-shadow: 1px 0 0 black inset;}
  .xterm-dom-renderer-owner-1 .xterm-rows .xterm-cursor.xterm-cursor-underline { border-bottom: 1px black; border-bottom-style: solid; height: calc(100% - 1px);}
}
